const EXCHANGE_KEY = 'exchange';
const DEFAULT_EXCHANGE = {
    cookieAccepted:false,
    instructionViewed:[]
};
class ExchangeUtil {

    getExchange=()=>{
        let excRaw = localStorage.getItem(EXCHANGE_KEY);
        let exchange = null;
        if(excRaw===null || typeof excRaw==='undefined'){
            localStorage.setItem(EXCHANGE_KEY, JSON.stringify(DEFAULT_EXCHANGE));
            exchange=DEFAULT_EXCHANGE;
        }else{
            try{
                exchange = JSON.parse(excRaw);
                this.handleModelUpdate(exchange);
            } catch(err){
                localStorage.setItem(EXCHANGE_KEY, JSON.stringify(DEFAULT_EXCHANGE));
            }
        }
        return exchange;
    }

    handleModelUpdate=(exchange)=>{
    
        // To avoid breaking change from browsers with old exchange model, add handling here for new fields
        let hasNew = false;
        if(exchange['instructionViewed']==null){
            exchange.instructionViewed = [];
        }
        if(exchange['cookieAccepted']==null){
            exchange.cookieAccepted = false;
        }

        if(hasNew){
            localStorage.setItem(EXCHANGE_KEY, JSON.stringify(exchange));
        }    
    }

    setExchangeProperty=(key, value)=>{
        let exchange = this.getExchange();
        exchange[key] = value;
        localStorage.setItem(EXCHANGE_KEY, JSON.stringify(exchange));

        return exchange;
    }

    pushToArray=(key, obj)=>{
        let exchange = this.getExchange();
        let targetArray = exchange[key];
        targetArray.push(obj);
        exchange[key] = targetArray;
        localStorage.setItem(EXCHANGE_KEY, JSON.stringify(exchange));
    }

    setCookieAccepted=(accepted)=>{
        this.setExchangeProperty('cookieAccepted', accepted);
    }

    setInstructionViewed=(templateId)=>{
        this.pushToArray('instructionViewed', templateId);
    }
}

export default new ExchangeUtil()