
import CanvasUtil from './CanvasUtil';
class ShareUtil {
    navigatorCanShare=(canvas)=>{
        let canShare = false;
        try {
            let data = CanvasUtil.convertToImageFile(canvas,'test-file');
            canShare = navigator.canShare(data);
          } catch (err) {
            console.error(err);
          }
          return canShare;
    }

    shareCanvasAsFileImage = async (canvas, fileName) => {
        let data = CanvasUtil.convertToImageFile(canvas,fileName);
        try {
            await navigator.share(data);
          } catch (err) {
            if (err.toString().includes('AbortError')) {
                console.log('Share aborted',err);
              }
          }
    }

    // shareCanvasAsFileImage=(canvas, fileName)=>{
    //     let uri = canvas.toDataURL('image/png', 1.0);
    //     var toBlob = require('canvas-to-blob');
    //     let canvasBlob = toBlob(uri);
    //     this.handleShare(canvasBlob,fileName);
    // }

    
// todo convert dataUri to url
    // dataUriToImgUrl=(dataUri)=>{
    //     axios.post('http://data-uri-to-img-url.herokuapp.com/images.json', userAccessRequest);
    // }


}
export default new ShareUtil()