import React, { Component } from 'react';
import TrackerPage from './TrackerPage';
import Button from '@mui/material/Button';

class TrackerPageWrapper extends Component {
    render() {
        return (
            <div className='tracker-page-wrapper'>
                <TrackerPage isWebview={this.props.isWebview} exchange={this.props.exchange} router={this.props.router}/>
            </div>
        );
    }
}

export default TrackerPageWrapper;