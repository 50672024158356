
export const GENERIC_MODAL_STYLE = {
    content : {
        transform:'translate(-50%, -50%)', top:'50%', left:'50%', right:'auto', bottom:'auto'
       ,boxShadow:'0px 2px 23px 4px rgba(0,0,0,0.23)', padding:'0px', border:'none',borderRadius:'8px'
    }
    , overlay: { backgroundColor: 'rgba(255,255,255,0.95)',zIndex:'10' }
}

export const INVISMODAL = {
    content : {
        transform:'translate(-50%, -50%)', top:'50%', left:'50%', right:'auto', bottom:'auto'
       ,boxShadow:'', padding:'0px', border:'none',backgroundColor:'transparent'
    }
    , overlay: {backgroundColor:'rgba(40,40,40,0.4)',zIndex:'10' }
}

export const ALLWHITE = {
    content : {
        transform:'translate(-50%, -50%)', top:'50%', left:'50%', right:'auto', bottom:'auto'
       ,boxShadow:'', padding:'0px', border:'none',backgroundColor:'white'
    }
    , overlay: {backgroundColor:'white',zIndex:'4' }
}

export const BLACKOUT_MODAL = {
    content : {
        transform:'translate(-50%, -50%)', top:'50%', left:'50%', right:'auto', bottom:'auto',backgroundColor:'transparent'
       ,boxShadow:'0px 2px 23px 4px rgba(0,0,0,0.23)', padding:'0px', border:'none',borderRadius:'10px'
    },
     overlay: { 
        transition:'0.4s',backgroundColor: 'rgba(0,0,0,1)',zIndex:'4' }
}

export const FIXED_BOTTOM_NOTIF_MODAL_STYLE = {
    content : {
        transform:'translate(-50%, -50%)', top:'90%', left:'50%', right:'auto', bottom:'auto'
        ,backgroundColor:'rgb(45,55,72)'
       ,boxShadow:'0px 2px 23px 4px rgba(0,0,0,0.23)', padding:'0px', border:'none',borderRadius:'8px'
    }
    , overlay: { backgroundColor: 'rgba(0,0,0,0.8)',zIndex:'4' }
}
