import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class TrackerItem extends Component {
    render() {
        if(typeof this.props.info==='undefined' || typeof this.props.status==='undefined') return null;
        let onExport = this.props.onExport;
        
        let width = 
        onExport?
          this.props.exportWidth :
           this.props.isMobile?'28vw':(this.props.isSmall?'130px':'200px');
        
        let height = 
        onExport?
          this.props.exportHeight :
          this.props.isMobile?'29vw':(this.props.isSmall?'150px':'210px');
        let backgroundColor =
        onExport &&  this.props.status.label===''?'rgba(0,0,0,0.05)':this.props.status.color
        return (
            <div className= {'tracker-item'}
                    style={{
                        width:width,
                        height:height,
                        margin: onExport?'0px 2px':'',
                        backgroundColor:backgroundColor,
                        opacity:this.props.opacity,
                        backgroundImage: onExport?"url("+this.props.info.url+")":''
                        }}
                    onClick={this.props.onClick}
                >
                {onExport==false&&
                <LazyLoadImage src={this.props.info.url}
                    width={'100%'} 
                    height="100%"
                    effect="blur" 
                    objectFit="contain"
                    style={{
                        width:'100%',height:'100%',
                        objectFit:'contain',
                        display: "inline-block"
                 }}
                 />}
                {(typeof this.props.info.subInfo!=='undefined' && this.props.info.subInfo!=='' && !this.props.onExport) && 
                    <p style={{fontSize:this.props.isMobile?'0.4rem':'0.6rem',fontWeight:'500',opacity:'0.8',position:'absolute',backgroundColor:'rgba(0,0,0,0.07)',padding:'1px 6px',borderRadius:'4px'}}>
                    {this.props.info.subInfo}
                    </p>
                }

                {!this.props.onExport&&
                    <h3 className='track-status logo-font' style={this.props.isMobile?{fontSize:'0.9rem',marginTop:'3px'}:{}}>{this.props.status.label}</h3>
                }
                    
                 {/* <h2 className='item-name' style={{height:'100%',margin:'0px 0px',textAlign:'left'}}>{this.props.info.label}</h2> */}
            </div>
        );
    }
}

export default TrackerItem;