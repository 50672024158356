import React, { Component } from 'react';
import history from '../history';
import TrackrLogo from '../assets/trackr-logo.svg';
import {HEADER_HEIGHT_PX} from '../config.js'

class HeaderComponent extends Component {

    constructor(props) {
        super(props); 
        this.state ={
            width:window.innerWidth,
            scrollPos:0,
        }
    }


    componentDidMount=()=>{
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.listenToScroll);
    } 
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
            scrollPos: scrolled,
        })
      }
      
    updateDimensions = () => {
        this.setState({ width:window.innerWidth});
    };

    isSmallWindow=()=>{
        return(
            this.state.width<600
        )
    }

    render() {
        return (
            <header className='header-comp' style={{position:'fixed',top:'0px',zIndex:'100000',backgroundColor:'rgb(31,39,48)', width:'100%', boxShadow: this.state.scrollPos!==0?'rgba(0,0,0,0.4) 0px -70px 100px 0px':'',height:HEADER_HEIGHT_PX+'px'}}>
              
                <div style={{display:'flex',overFlow:'hidden'}}>
                    <div style={{cursor:'pointer',width:'100%',display:'flex'}} onClick={()=>{ 
                            history.push('/');
                            history.go();
                        }}>
                        <img style={{width:'140px',padding:'13px 8px'}} src={TrackrLogo}/>
                    </div>
                    <div style={{width:'100%'}}>
                        
                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderComponent;