import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class GuideModal extends Component {

    constructor(props) {
        super(props); 
        this.state ={
            imageIdx:0
        }
    }
    async componentDidMount(){ 
    }

    hasNextImage=()=>{
        return this.state.imageIdx<this.props.template.guideImage.length-1;
    }
    onButtonClick=()=>{
        if(this.hasNextImage()){
            this.setState({imageIdx:this.state.imageIdx+1})
        } else {
            this.props.onCloseEvent();
        }
    }

    nextGuideImage=()=>{
    }

    render() {
        return (
            <div className='guide-modal modal' style={{height:"100%",justifyContent:'center',paddingTop:isMobile?'8vh':'5vh'}}>

                <div style={{display:'flex',justifyContent:'center',height:isMobile?'80%':'84%'}}>
                    
                    <LazyLoadImage
                        height={'100%'}
                        effect="blur"
                        alt={'Instruction image for ' + this.props.template.title + ' - trackr.cc'}
                        src={this.props.template.guideImage[this.state.imageIdx]}
                        width={'100%'}
                        style={{height:'100%', maxWidth:'100%', objectFit: "contain",borderRadius:'10px',overflow:'hidden'}}
                        />
                </div>

                <div style={{width:'100%',justifyContent:'center',display:'flex',padding:'15px'}}>
                    <Button variant="contained" 
                        onClick={this.onButtonClick} 
                        style={{fontSize:'1.2rem',width:isMobile?'80%':'40%',minWidth:'320px',height:'56px'}}>
                        {this.hasNextImage()?'Next':"Let's go!"}
                    </Button>
                </div>
            </div>
        );
    }
}

export default GuideModal;