import axios, { AxiosRequestConfig } from "axios";
import { saveAs } from 'file-saver';
import FirebaseUtil from './FirebaseUtil';
import Util from './Util';
class CanvasUtil{

    isConversionSupported=()=>{
        var toBlob = require('canvas-to-blob');
        return toBlob.supported;
    }

    downloadAsPNG = async (canvas, fileName) => {
        let uri = canvas.toDataURL('image/png', 1.0);
        var toBlob = require('canvas-to-blob');
        let canvasBlob = await toBlob(uri);
        saveAs(canvasBlob, fileName);
        // window.open('https://yt3.googleusercontent.com/fGvQjp1vAT1R4bAKTFLaSbdsfdYFDwAzVjeRVQeikH22bvHWsGULZdwIkpZXktcXZc5gFJuA3w=s900-c-k-c0x00ffffff-no-rj');
        // saveAs('https://codingnomads.co/wp-content/uploads/2020/12/OOP-graphic-blog-oop-concepts-in-java-what-is-object-oriented-programming.png', "asdasdasda.jpg");
        // Util.openInNewTab('https://codingnomads.co/wp-content/uploads/2020/12/OOP-graphic-blog-oop-concepts-in-java-what-is-object-oriented-programming.png')
        // this.saveFromUrl('https://yt3.googleusercontent.com/fGvQjp1vAT1R4bAKTFLaSbdsfdYFDwAzVjeRVQeikH22bvHWsGULZdwIkpZXktcXZc5gFJuA3w=s900-c-k-c0x00ffffff-no-rj','ntente')
        // FirebaseUtil.saveDataUri({dataUri:uri});

    }

    openToNewTab=async (canvas,fileName)=>{

      let uri = canvas.toDataURL('image/png', 1.0);
      var toBlob = require('canvas-to-blob');
      let canvasBlob =await toBlob(uri);

      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let  url = window.URL.createObjectURL(canvasBlob);
      a.href = uri;
      a.download = fileName;
      window.open(url);
      window.URL.revokeObjectURL(url);
    }

    convertToImageFile=(canvas, fileName)=>{

        let uri = canvas.toDataURL('image/png', 1.0);
        let toBlob = require('canvas-to-blob');
        let canvasBlob = toBlob(uri);
        const data = {
            files: [
              new File([canvasBlob], fileName+'.png', {
                type: canvasBlob.type,
              }),
            ],
            title: fileName,
            text: 'Check out my ' + fileName
          };
        return data;
    }

    saveFromUrl = (uri, filename) => {
        // const link = document.createElement('a');

        // if (typeof link.download === 'string') {
        //     link.href = uri;
        //     link.download = filename;
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // } else {
        //     window.open(uri);
        // }
        // console.log(uri);
        fetch(uri, {
          method: "GET",
          headers: {}
        })
          .then(response => {
            response.arrayBuffer().then(function(buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "image.png"); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch(err => {
            console.log(err);
          });
    }
}

export default new CanvasUtil()