import React, { Component } from 'react';
import '../css/progressbar.css';

class ProgressBar extends Component {
    render() {
        let color = '';

        if(this.props.percentage<20){
            color = 'rgb(162, 161, 161)';
        }else if(this.props.percentage<40){
            color = 'rgb(255, 104, 104)';//red
        } else if(this.props.percentage<60){
            color = 'rgb(255, 156, 94)';//orange
        } else if(this.props.percentage<90){
            color = 'rgb(255, 221, 98)';//yellow
        } else if(this.props.percentage<100){
            color = 'rgb(169, 219, 87)';//lightgreen
        } else if(this.props.percentage==100){
            color = 'rgb(101, 208, 121)';//green
        } 
        return (
            <div class="progress-bar" style={{height:'30px',textAlign:'center'}}>
                <div class="progress-bar-loader" style={{width:this.props.percentage+'%',backgroundColor:color}}></div>
                <h3 className='progress-label' style={{color:this.props.percentage==100?'white':''}}>{Math.round(this.props.percentage)+'%'}</h3>
          </div>
        );
    }
}

export default ProgressBar;