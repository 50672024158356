
class Util {
   openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  findSearchQueryParam=(location,name)=>{
    const search = location.search;
    const params = new URLSearchParams(search);
    return params.get(name);
  }

}

export default new Util()