import React from 'react';
import './App.css';
import 'animate.css';
import logo from './logo.svg';
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router, Navigate, Routes, Route, Link } from "react-router-dom";
import history from './history';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import CookieNotif from './components/CookieNotif';
import TrackerPage from './trackers/TrackerPage';
import TrackerPageWrapper from './trackers/TrackerPageWrapper';
import TemplateTool from './pages/TemplateTool';

import MapLevel from './trackers/MapLevel';
import HomePage from './pages/HomePage';
import CookiePolicy from './pages/legal/CookiePolicy';
import ExchangeUtil from './utils/ExchangeUtil';
import {Helmet} from "react-helmet";
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width:window.innerWidth,
      rerenderTempState:false
    }
  }

  async componentDidMount(){
      window.addEventListener('resize', this.updateDimensions);
  }


  componentWillUnmount(){
      window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
      if(this.state.width != window.innerWidth){
          this.setState({ width:window.innerWidth});
      }
  }

  getWebViewDetails=()=>{

    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;
    
    const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    const isAndroid = /android/.test(normalizedUserAgent)
    const isSafari = /safari/.test(normalizedUserAgent)
    const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)
    
    let viewProps = (isIos?'ios ':'') + (isAndroid?'android ':'') + (isSafari?'safari ':'') + (isWebview?'webview ':'');
    
    return isWebview;
  }

render(){
  let isWebview = this.getWebViewDetails();
  let isSmall = this.state.width<800 || isMobile;

  let exchange = ExchangeUtil.getExchange();
  if(exchange!==null){
    exchange.isSmall = isSmall;
  }

  return(
      <div className="trackr-web" style={{height:'100vh',width:'100%'}}>
            <Helmet>
                 <meta charSet="utf-8" />
            </Helmet>
            <HeaderComponent />
            <div className='main-controller'>
              <Router history={history}>
              
                <Routes>
                    <Route path='/' element={<HomePage exchange={exchange}/>} /> 
                    {/* <Route path='/admin' element={<TemplateTool exchange={exchange}/>} />  */}
                    <Route path='/:trackerType?/:templateId?' element={<TrackerPageWrapper isWebview={isWebview} exchange={exchange}/>} /> 
                    <Route path='/pages/cookie-policy' element={<CookiePolicy exchange={exchange}/>} />
                    
                </Routes>
              </Router>
              {(exchange===null || exchange.cookieAccepted===false) && 
              <CookieNotif onAccept={()=>{this.setState({rerenderTempState:false});}}/>}
              
            </div>
      </div>
    );
  }
}

export default App;
