
import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDhUW29oRLTpJyQmZBht3yXGTVbcX0mBC0",
    authDomain: "trackr-e9630.firebaseapp.com",
    projectId: "trackr-e9630",
    storageBucket: "trackr-e9630.appspot.com",
    messagingSenderId: "448378668674",
    appId: "1:448378668674:web:2e9b4e5f01eb384149569b",
    measurementId: "G-KM2TGK0XJH"
  };
  
  
const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDb = getFirestore(firebaseApp);