

import { collection, addDoc, getFirestore, getDoc, doc, setDoc, updateDoc} from "firebase/firestore";
 
import { firestoreDb } from "../integrations/firebase";
const TEMPLATE_COLLECTION = 'templates';
const TEMPLATE_METADATA_COLLECTION = 'template-metadata';
class FirebaseUtil {

    saveDataUri = async (img) => {
      
        try {
            const docRef = await addDoc(collection(firestoreDb, "export-img"), {
              dataUri: img.dataUri,    
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }
     // tracker_id = documentId
     createNewTeplate = async (templateJson) => {
      console.log('Will save template',templateJson);
      try {
        const tryCtrRef = await addDoc(collection(firestoreDb, TEMPLATE_METADATA_COLLECTION), {
          tryCtr: 0
        }).then(snap=>{
            console.log("TryCtr Document written with ID: ", snap.id);
            templateJson.metadataId= snap.id;
            setDoc(doc(firestoreDb, TEMPLATE_COLLECTION, templateJson.templateId), templateJson).then(temp=>{
              console.log("New template created with ID: ", templateJson.templateId);
            });
        });
      
      } catch (e) {
        console.error("Error adding document: ", e);
      }
  }

    // tracker_id = documentId
    getTemplateDetails = async (tracker_id, callback) => {
        try {
            getDoc(doc(collection(firestoreDb, TEMPLATE_COLLECTION), tracker_id)).then(snap => {
              let templateData=snap.data();
              let notFound = templateData == null;
              if(!notFound && templateData.metadataId!=null){
                  getDoc(doc(collection(firestoreDb, TEMPLATE_METADATA_COLLECTION), templateData.metadataId)).then(metaSnap => {
                    if(typeof metaSnap !== 'undefined'){
                      templateData.metadata = metaSnap.data();
                    }
                    callback(templateData); 
                  });
              }else{
                  callback(null); 
              } 
          }); 
      } catch (e) {
        console.error("Error retrieving document: ", e);
      } 
    }

    // tracker_id = documentId
    addTrackerTryCtr = async (template, callback) => {
      try {
        let metadata_id=template.metadataId;
          getDoc(doc(collection(firestoreDb, TEMPLATE_METADATA_COLLECTION), metadata_id)).then(snap => {
            let trackerData=snap.data();
            if(typeof trackerData !== 'undefined'){
                updateDoc(doc(firestoreDb, TEMPLATE_METADATA_COLLECTION, metadata_id), 
                {
                    tryCtr: trackerData.tryCtr+1
                }
              );
            } else {
              console.error("Error retrieving document ["+metadata_id+"]: ");
            }
          }); 
      } catch (e) {
        console.error("Error retrieving document: ", e);
      } 
  }
}


export default new FirebaseUtil()