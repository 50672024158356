import React, { Component } from 'react';
import Modal from 'react-modal';
import history from '../history';
import { Link } from "react-router-dom"; 
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';
import { isMobile,isDesktop} from 'react-device-detect';
import BeatLoader from "react-spinners/BeatLoader";
import MoonLoader from "react-spinners/MoonLoader";
import {SHOW_ADS,TRACKER_SUBMIT_CONTAINER_HEIGHT,HEADER_HEIGHT_PX,INSTRUCTION_HEAD_HEIGHT_PX} from '../config.js';
import InstructionComp from './components/InstructionComp';
import AdComponent from '../components/AdComponent';
import withRouter from '../utils/WithRouter';
import Util from '../utils/Util';
import ExchangeUtil from '../utils/ExchangeUtil';
import './TrackerPage.css';
import MapLevel from './MapLevel';
import PercentageProgressTracker from './PercentageProgressTracker';
import { GENERIC_MODAL_STYLE, BLACKOUT_MODAL, INVISMODAL, ALLWHITE } from '../modals/ModalProperties';

import GuideModal from '../modals/GuideModal';
import TrackerComplete from '../modals/TrackerComplete';
const EXPORT_HEADER_HEIGHT_PX = 94;
class ExportFooter extends Component {
    render() {
        let top = 100;
        let left = 0;
        if(this.props.template !=null && this.props.template.meta!=null && this.props.template.meta.waterMarkPosition!=null){
            top=this.props.template.meta.waterMarkPosition.top;
            left=this.props.template.meta.waterMarkPosition.left;
        }
        return (
            <div style={{textAlign:'center',display:'flex', position:'absolute',top:top+'px',left:left+'px', justifyContent:'center'}}>
                <p style={{margin:'0px',fontWeight:'300',fontSize:'0.6rem',marginTop:'8px',marginRight:'4px'}}>generated  at</p>
                <h2 className='logo-font' style={{fontSize:'1rem',marginTop:'0px'}}>trackr.cc</h2>
            </div>
        )
    }
}
export default withRouter(class TrackerPage extends Component {

    constructor(props) {
        super(props); 
        this.captureDivRef = React.createRef();
        this.state ={
            onExport:false,
            onCompleteModal:false,
            onInstructionModal:false,
            imageExport:null,
            canvasWidth:0,
            canvasHeight:0
        }

    }

    setTemplateData=(retData)=>{
        this.setState({template:retData,onInstructionModal:this.shouldShowInstruction(retData)},()=>{

            if(this.state.template!=null && this.state.template.meta!=null && this.state.template.meta.startScroll!=null){

                this.captureDivRef.current.scroll(this.state.template.meta.startScroll.left,this.state.template.meta.startScroll.top);
                window.scrollTo(0, this.state.template.meta.startScroll.top);
                this.setState({onExport:false});// just need to update any state to rerender.. I know.. i know..
             
            }

        });
    }
    
    shouldShowInstruction=(template)=>{
        let hasGuideImg = template!=null && template.guideImage!=null; 
        let exchange = ExchangeUtil.getExchange();
        let haveShownGuide = exchange['instructionViewed'].includes(this.props.router.params.templateId);
        return hasGuideImg && haveShownGuide==false;
    }

    showInstruction=()=>{
        this.setState({onInstructionModal:true});
    }

    async componentDidMount(){ 
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }

    componentWillReceiveProps (newProps) {
        if( newProps.router !== this.props.router ){
            let completeSearchParam = Util.findSearchQueryParam(this.props.router.location,'complete');
            let newCompleteSearchParam = Util.findSearchQueryParam(newProps.router.location,'complete');
            if(completeSearchParam!=null && newCompleteSearchParam==null){
               this.exitCompleteModal();
            }
        }
    }

    exitCompleteModal=()=>{
        this.setState({onCompleteModal:false,imageExport:null});
        history.push('/'+this.props.router.params.trackerType+'/'+this.props.router.params.templateId);
        window.scrollTo(0, 0);
    }

    renderModals=()=>{
        if(this.state.onInstructionModal){
            return (
                <Modal id="modal-container" isOpen={true} style={INVISMODAL}>   
                    <div className='guide-modal-container' 
                    style={{width: isMobile?'100vw':'70vw', minWidth:isMobile?'':'500px',height:'98vh',overflow:'hidden'}}
                    >
                    <GuideModal 
                        onCloseEvent={()=>{
                            ExchangeUtil.pushToArray('instructionViewed',this.props.router.params.templateId);
                            this.setState({onInstructionModal:false});
                        }}
                        imageExport={this.state.imageExport}
                        isWebview={this.props.isWebview}
                        template={this.state.template}
                    />
                    </div>
                </Modal>
                );
        } else if(this.state.onCompleteModal){
            return (
            <Modal id="modal-container" isOpen={true} style={ALLWHITE}>   
                <div className='tracker-complete-modal-container' 
                style={{width: isMobile?'94vw':'70vw', minWidth:isMobile?'':'500px',height:isMobile?'85vh':'100vh',overflow:'hidden',paddingTop:HEADER_HEIGHT_PX}}
                >
                <TrackerComplete 
                    onCloseEvent={this.exitCompleteModal}
                    imageExport={this.state.imageExport}
                    isWebview={this.props.isWebview}
                    template={this.state.template}
                />
                </div>
            </Modal>
            );
        }
        
    }

    renderPageHide=()=>{
        return(
            <Modal id="modal-container" isOpen={this.state.onExport} style={BLACKOUT_MODAL}>   
                  <BeatLoader color={'rgb(253, 174, 78)'} loading={true} css={{position:'absolute', marginLeft:'-11vh',marginTop:'25vh'}} size={20} />
            </Modal>
        )
    }

    exportToPNG = async ()=>{
        this.setState({onExport:true},()=>{
            const element = ReactDOM.findDOMNode(this.captureDivRef.current);
            this.captureDivRef.current.scroll(0,0);
            this.setState({onExport:false, onCompleteModal:true});
            html2canvas(element, {scrollY: -window.scrollY, useCORS: true}).then(
                canvas => {
                    this.setState({imageExport:canvas});
                });
        });
    }
    
    renderLoadingScreen=()=>{
        return(
            <div className='loading' style={{width:'100%',display:'flex',justifyContent:'center',padding:'20vh 0 25% 0'}}>
                <MoonLoader color={'rgb(253, 174, 78)'} loading={true} css={{}} size={40} />
                <h2 style={{color:'whitesmoke',margin:'12px 0px 0px 12px'}}>Please wait</h2>
            </div>
        );
    }

    renderTemplateNotFound=()=>{
        return(
            <div className='loading' style={{width:'100%',padding:'24vh 0 24vh 0',textAlign:'center'}}>
                <h2 style={{fontWeight:'400',color:'whitesmoke',margin:'12px 0px 0px 12px'}}> Ooops! Template not found 😵‍💫</h2>
                <a className='basic-link' href={'/'}>visit homepage</a>
            </div>
        );
    }

    setCanvasDimension=(canvasWidth,canvasHeight)=>{
        this.setState({canvasWidth:canvasWidth,canvasHeight:canvasHeight})
    }

    renderTrackerType=(onExport)=>{
        switch(this.props.router.params.trackerType){
            case 'progress': 
                return (
                    <PercentageProgressTracker
                        setTemplateData={this.setTemplateData}
                        templateId={this.props.router.params.templateId}
                        onExport={onExport} 
                        isSmall={this.props.exchange.isSmall}
                        setCanvasDimension={this.setCanvasDimension}
                    />);
            break;
            case 'map':
                return (
                    <MapLevel
                        setTemplateData={this.setTemplateData}
                        templateId={this.props.router.params.templateId}
                        onExport={onExport} 
                        exchange={this.props.exchange}
                        setCanvasDimension={this.setCanvasDimension}
                    />);
            break;
        }
        return null;
       
    } 

    getScrollY = ()=>{
        const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
        
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
    
      const scrolledY = winScroll / height;

      return winScroll;
    }

    render() {
        let onExport = this.state.onExport; // always have this variable, for debugging purposes
        // let onExport = true; // always have this variable, for debugging purposes
        let showHorizontalAds = SHOW_ADS && this.props.exchange.isSmall===false && isMobile===false;
        let trackerLoaded = (typeof this.state.template!=='undefined' && this.state.template!==null)

        let canvasExportWidth =this.state.canvasWidth;
        let canvasExportHeight =this.state.canvasHeight;
        if(onExport && this.state.template != null && this.state.template.meta!=null&& this.state.template.meta.exportDimension!=null){
            canvasExportWidth = this.state.template.meta.exportDimension.width;
            canvasExportHeight = this.state.template.meta.exportDimension.height;
        }

        let scrollableCaptureDiv = true;
        return (
            <div className='tracker-page' style={{backgroundColor:trackerLoaded?'white':'',paddingTop:HEADER_HEIGHT_PX}}>
                {this.renderPageHide()}
                {this.renderModals()}

                {this.state.onCompleteModal===false&&
                    <InstructionComp template={this.state.template} showInstruction={this.showInstruction}/>}
                <div className='tracker-main-cont' style={{height:scrollableCaptureDiv?'calc(100% - '+TRACKER_SUBMIT_CONTAINER_HEIGHT + 'px)':'',paddingBottom:TRACKER_SUBMIT_CONTAINER_HEIGHT}}>
                    {showHorizontalAds&& <div className='add-block-vertical-left' style={{width:'10vw'}}><AdComponent/></div>}
                    <div className='capture-div-cont' style={{width:showHorizontalAds?'80vw':'100vw'}}>
                        <div className={onExport?'capture-div-onexport':'capture-div'} ref={this.captureDivRef}
                            style={onExport?{width:+canvasExportWidth+'px',height:(canvasExportHeight+EXPORT_HEADER_HEIGHT_PX)+'px'}:{paddingTop:INSTRUCTION_HEAD_HEIGHT_PX}}>
                                {this.renderTrackerType(onExport)}
                                {(typeof this.state.template==='undefined')&&this.renderLoadingScreen()}
                                {(this.state.template===null)&&this.renderTemplateNotFound()}
                                {onExport && <ExportFooter template={this.state.template}/>}
                        </div>
                    </div>
                    {showHorizontalAds &&  <div className='add-block-vertical-right' style={{width:'10vw'}}></div>}
                </div>
                {trackerLoaded && this.state.onCompleteModal===false && this.state.onInstructionModal==false&&
                    <div className={isMobile?'submit-container-mobile':'submit-container'} style={{height:TRACKER_SUBMIT_CONTAINER_HEIGHT+'px'}}>
                        <Link className='submit-btn' onClick={this.exportToPNG}
                            to={'/'+this.props.router.params.trackerType+'/'+this.props.router.params.templateId+'?complete=1'}>
                            Generate image
                        </Link>
                    </div>}
                {SHOW_ADS&&<div style={{display:'flex',justifyContent:'center'}}>
                    <ins className = "adsbygoogle"
                        style = { {display:"inline-block",width:"70vw",minHeight:"90px"} }
                        data-ad-client = "ca-pub-4061123336539880"
                        data-ad-slot = "2736946882"></ins>
                </div>}
            </div>
        );
    }
});