import {Helmet} from "react-helmet";
class PageMetaUtil{

    generateHelmetFromTemplate=(templateId, template)=>{
        if(template==null) {return null}
        let pageUrl = 'https://www.trackr.cc/'+template.type.toLowerCase()+'/'+templateId;
        return (
            <Helmet>
                <title>{template.title + ' - Trackr'}</title>
                <link rel="canonical" href={pageUrl} />
                <meta name="description" content={template.description} />
            </Helmet>
        )
    }

}

export default new PageMetaUtil();