import React, { Component } from 'react';
import Util from "../utils/Util";
class FooterComponent extends Component {
    render() {
        return (
            <div className='footer-component' style={{width:'100%'}}>

            <div className='footer-home' style={{display:'flex',padding:'20px 14vw',borderTop:'solid 1px rgb(23, 54, 74)'}}>

                {/* <div className='explore-sec' style={{width:'100%'}}>
                        <h3 className='exsec-head'>CREATE</h3>
                        <p className='exsec-opt'>Create Status Map</p>
                    </div>
                    <div className='explore-sec' style={{width:'100%'}}>
                        <h3 className='exsec-head'>EXPLORE</h3>
                        <p className='exsec-opt'>All tracker</p>
                        <p className='exsec-opt'>Categories</p>
                        <p className='exsec-opt'>Tags</p>
                    </div> */}
                    <div className='explore-sec' style={{width:'100%'}}>
                        <h3 className='exsec-head'>LEGAL</h3>
                        {/* <p className='exsec-opt'>Privacy</p>
                        <p className='exsec-opt'>Terms</p> */}
                        <p className='exsec-opt' onClick={()=>{Util.openInNewTab('https://trackr.cc/pages/cookie-policy');}}>Cookies</p>
                    </div>
                </div>
                <div className='some-info' style={{height:'10vh'}}>

                </div>

                <div className='some-some-info' style={{height:'6vh',display:'flex',padding:'12px 3vw'}}>
                    <p style={{margin:'0px',color:'rgb(122,122,122)'}}>© 2023 yujinccc</p>
                    <i style={{cursor:'pointer',marginLeft:'4px',color:'rgb(122,122,122)'}} 
                    onClick={()=>{Util.openInNewTab('https://www.linkedin.com/in/eugene-c-caranto/');}}>&#x1f517;</i>
                </div>
                
            </div>
        );
    }
}

export default FooterComponent;