import React, { Component } from 'react';

class AdComponent extends Component {
    render() {
        return (
            <ins className = "adsbygoogle"
                style = {{display:"inline-block",width:"70vw",minHeight:"90px"} }
                data-ad-client = "ca-pub-4061123336539880"
                data-ad-slot = "7222986804"></ins>
        );
    }
}

export default AdComponent;