import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import {INSTRUCTION_HEAD_HEIGHT_PX} from '../../config.js';

class InstructionComp extends Component {
    render() {
        if(this.props.template!=null && this.props.template.instruction!=null){
            let hasGuideImg = this.props.template.guideImage!=null;
            return (
                <div className='instruction-div' style={{position:'absolute', textAlign:'center', zIndex:'4', height:INSTRUCTION_HEAD_HEIGHT_PX, width:'100%',display:'flex',justifyContent:'center',padding:'8px' }}>
                    <div className='inst-content' style={{fontSize:isMobile?'0.8rem':'', color:'rgb(63,63,64)', fontWeight:isMobile?'400':'', width:'100%', padding:isMobile?'6px':'8px 4vw',backgroundColor:'rgb(245,245,246)',borderRadius:'10px'}}>
                        <span style={{margin:'0px',opacity:'0.9',marginRight:'3px'}}>{this.props.template.instruction}</span>
                        {(hasGuideImg) &&<span className='text-btn' onClick={this.props.showInstruction}>ⓘ</span>}
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default InstructionComp;