import React, { Component } from 'react';
import ExchangeUtil from '../utils/ExchangeUtil';
import Modal from 'react-modal';
import 'animate.css';

import { isMobile } from 'react-device-detect';
// import { FIXED_BOTTOM_NOTIF_MODAL_STYLE } from '../modals/ModalProperties';
class CookieNotif extends Component {
    render() {
        if(window.location.href.includes('/pages/cookie-policy')){
            return null;
        }
        return (
            // uncomment if you want to make it fixed
            // <Modal id="modal-container" isOpen={true} style={FIXED_BOTTOM_NOTIF_MODAL_STYLE}>   
                <div className='notif-bottom animate__animated animate__headShake'>
                    <p style={isMobile?{width:'70vw'}:{}}>
                        <span>By using our website, you agree to the use of cookies as described in our </span>
                        <a href='/pages/cookie-policy' style={{color:'orange'}}>cookie policy.</a>
                    </p>
                    <div className='quick-action-button' onClick={()=>{
                        ExchangeUtil.setCookieAccepted(true);
                        this.props.onAccept();
                    }}
                        style={{marginLeft:'2vw',marginTop:isMobile?'6%':'3px',width:'26vw', padding:'10px',height:'40px',fontSize:'inherit'}}>
                        Accept
                    </div>
                </div>
            // </Modal>
        );
    }
}

export default CookieNotif;