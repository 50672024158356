import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { isMobile } from 'react-device-detect';
import FeatureTemplate from '../components/FeatureTemplate';
import MockHomeData from './mockHomeData.json';
import FooterComponent from '../components/FooterComponent';
const HORIZONTAL_PADDING = '2vw';
 
class HomePage extends Component {

    renderFeaturedTemplates=()=>{

        // todo, fetch homedata from firebase
        let homeData = MockHomeData;

        if(homeData==null || homeData.featuredTemplateIds==null) {return null;}

        let featureTemplate=[]; 
        homeData.featuredTemplateIds.forEach((templateId,idx)=>{
            featureTemplate.push(<FeatureTemplate template_id={templateId} isSmall={this.props.exchange.isSmall}/>);
        });

        let featureTemplateGridItem=[]; 
        featureTemplate.forEach((ftrdTemplate,idx)=>{
            featureTemplateGridItem.push(
                <div className='ftrd-grid-item-wrapper' style={{maxWidth:'360px',minWidth:'320px',marginRight:'12px', height:'305px'}}>
                    {ftrdTemplate}
                </div>
            )
        });

        return (
            <div className='featured-content' style={{width:'100%'}}>
                <h2 className={this.props.exchange.isSmall?'h2-mobile':'h2'}>🔥 Featured Templates</h2>
                <div className='ftrd-grid' style={{width:'100%',display:'flex',overflowX:'scroll',overflowY:'hidden'}}>
                    {featureTemplateGridItem}
                </div>
            </div>
        );
    }

    render() {
        let banner_name = 'wlc-banner'+(this.props.exchange.isSmall?' wlc-b-mobile':'');
        let showAds=false;
        return (
            <div className='home-page page' style={{color:'white'}}>
                <Helmet>
                    <title>{'Trackr | Celebrate every Milestone'}</title>
                    <link rel="canonical" href={"https://www.trackr.cc"} />
                    <meta name="description" content="Your ultimate destination for fun progress tracking! We've got you covered with interactive and engaging checklists that make tracking your progress a joyous and fun experience" />
                </Helmet>
                    <div className='welcome-stmt' style={{width:'100%',textAlign:'center',paddingTop:'88px'}}>
                        <span className={banner_name} style={{color:'rgb(237,83,105)'}}>Track</span>
                        <span className={banner_name}>your</span>
                        <span className={banner_name} style={{color:'rgb(237,83,105)'}}>Progress</span>
                        <div>
                            <p className={banner_name} 
                            style={
                                this.props.exchange.isSmall?{fontSize:'22px', fontWeight:'800'}:{fontSize:'36px', fontWeight:''}
                                }>
                            Celebrate every Milestone 🚀</p>
                        </div>
                          {/* <p>Here at Trackr, we value your privacy and security. Rest assured that your personal information and progress data are protected, allowing you to focus on having fun and smashing your goals.</p> */}
                    </div>
                <br/>
                <div className='main-content' style={{padding:'70px '+HORIZONTAL_PADDING}}>
                    <div className='port-1' style={{width:this.props.exchange.isSmall||showAds==false?'100%':'74vw'}}>
                        {this.renderFeaturedTemplates()}
                    </div>
                    {showAds&&
                    <div className='ad-div-54345234' style={{minWidth:'26vw'}}>
                    </div>}

                </div>
                <br/><br/>

                <div className='about-trackr' style={{backgroundColor:'rgba(255,255,255,0.03)',padding:'10px '+HORIZONTAL_PADDING}}>
                    <h2 style={{color:'rgb(248, 243, 253)'}}>Your ultimate destination for fun progress tracking! ✅</h2>
                    <div style={{opacity:'0.9'}}>
                        <p>Whether you're completing a movie franchise, traveling around the globe, or simply seeking a playful way to track your progress. </p>
                        <p>We've got you covered with interactive and engaging checklists that make tracking your progress a joyous and fun experience.</p>
                        <p>Embrace the joy of checking off tasks, and celebrate every milestone along the way.</p>
                    </div>
                </div> 
                <br/>
                <br/>
                <br/>

              <FooterComponent/>
            </div>
        );
    }
}

export default HomePage;