import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { isMobile } from 'react-device-detect';
import CanvasUtil from '../utils/CanvasUtil';
import ShareUtil from '../utils/ShareUtil';
import FirebaseUtil from '../utils/FirebaseUtil';
import BeatLoader from "react-spinners/BeatLoader";
import {EmailShareButton, FacebookShareButton }from "react-share";
import { PiShareNetworkBold } from "react-icons/pi";
import { IoCloseCircleSharp } from "react-icons/io5";
class TrackerComplete extends Component {

    constructor(props) {
        super(props);
        this.state ={
        }
    } 

    async componentDidMount(){
    }

    componentDidUpdate(prevProps) {
        if(window.location.href.includes('http://localhost:3000')==false  &&
        window.location.href.includes('192.168.100.72:3000')==false)
        if (this.props.imageExport !== prevProps.imageExport && this.props.imageExport!= null) {
            FirebaseUtil.addTrackerTryCtr(this.props.template);
        }
    }
    
    shareToInstagram=()=>{

    }
    shareToFacebook=()=>{

    }
    shareToMessenger=()=>{

    }

    renderShareImageBtn=(footerHeightPx)=>{
        if(ShareUtil.navigatorCanShare(this.props.imageExport)){
            return (
            <div className='cool-button'
                onClick={()=>{
                    ShareUtil.shareCanvasAsFileImage(this.props.imageExport,'Check out my '+this.props.template.title);
                    }}
                    style={{textAlign:'center', marginLeft:'1px',height:'calc('+footerHeightPx+'px - 18px)', fontSize:'1.15rem', width:'100%',"--c":"#2ca2a4"}}>
                <PiShareNetworkBold size={isMobile?'1rem':'1.25em'}/> 
            </div>)
        }
            
    }

    downloadImage=()=>{ 
        CanvasUtil.downloadAsPNG(this.props.imageExport, this.props.template.title);
    }

    isImageLoaded=()=>{
        return typeof this.props.imageExport !== 'undefined' && this.props.imageExport!= null;
    }
    
    isDownloadSupported=()=>{
        return CanvasUtil.isConversionSupported() && this.props.isWebview==false;
    }

    renderResultImg=()=>{
        if(this.isImageLoaded()) { 
            return <img className='result-img' src={this.props.imageExport.toDataURL()} />
        } else 
            return <div style={{paddingTop:'25%',paddingBottom:'3vh'}}>
                        <BeatLoader 
                            color={'rgb(253, 174, 78)'} 
                            loading={true}  css={{position:'absolute', marginLeft:'-11vh',marginTop:'25vh'}} 
                            size={14} />
                    </div>;
    }

    render() {
        let headerHeightPx = 50;
        let footerHeightPx = isMobile?70:65;
        return (
            <div className='window tracker-complete' style={{overflow:'hidden', height:'100%'}}>
                {this.isDownloadSupported() &&
                <div className='modal-header' style={{border:'none', padding:isMobile?'0px 20px':'14px',fontSize:'1.2rem',height:headerHeightPx+'px',position:isMobile?'absolute':''}}>
                    {isMobile&&<div style={{width:'100%'}}></div>}
                   {isMobile==false&&<p style={{fontWeight:'600',color:'rgba(0,0,0,0.7)',textAlign:'center',height:'100%', margin:'0px',width:'100%',paddingLeft:'30px'}}>
                   {'✨ Share to your friends'}</p>}
                  {this.isImageLoaded()&& <IoCloseCircleSharp className='close-modal-btn' size={isMobile?'38px':'40px'} style={isMobile?{fontSize:'1.3rem',position:'absolute',left:'calc(100vw - 60px)',zIndex:'1000000'}:{}} onClick={this.props.onCloseEvent}/> }
                </div> }

                <div className='result-img-cont' style={{height:'calc(100% - '+(headerHeightPx+footerHeightPx)+'px)',paddingTop:isMobile?'0px':'',paddingBottom:isMobile?'0px':''}}>
                    {this.renderResultImg()}
                </div>
                <div style={{height:(footerHeightPx-15)+'px'}}>
                    {/* <div style={{height:'100%'}}></div> */}
                    <div className='actions-div' style={{justifyContent:'center',display:'flex',height:'100%', width:'100%'}}>
                            {this.isImageLoaded() && this.isDownloadSupported() &&
                            <button className='cool-button' onClick={this.downloadImage} style={{height:'calc('+footerHeightPx+'px - 18px)',fontSize:isMobile?'0.9rem':'1.1rem', width:'100%',"--c":"#2ca2a4"}}>
                                 Download Image 
                            </button>}
                            <br/>
                            {this.isImageLoaded() && this.renderShareImageBtn(footerHeightPx)}
                    </div>
                </div>
                {isMobile&& this.isImageLoaded()&&
                <p style={{transform:'translate(-50%, -50%)',top:'97%',left:'50%',position:'absolute',  whiteSpace: "pre-line",fontSize:'0.6rem',margin:'2px 0px 0px 0px',textAlign:'center',opacity:'0.4'}}>{"Take screenshot\nif download is not available"}</p>}
                <br/>
            </div>
        );
    }
}

export default TrackerComplete;