
import React, { Component, useState, useEffect} from 'react';
import { Link } from "react-router-dom"; 
import history from '../history';
import FirebaseUtil from '../utils/FirebaseUtil';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const FeatureTemplate =(props)=> {
    const [templateDocument,setTemplateDocument]=useState([]);

    const fetchFirebaseDocuments=async()=>{
        FirebaseUtil.getTemplateDetails(props.template_id, setTemplateDocument);
    }

    useEffect(() => {
        fetchFirebaseDocuments();
    }, [])

    const renderCategories=()=>{
        if(templateDocument.category==null) return null;
        let category = JSON.parse(templateDocument.category);
        let ftrdCategory = [];
        category.items.forEach(item=>{
            ftrdCategory.push( <p className='ftrd-category'>{item.label}</p>);
        })
        return(
            <div className='categories-cont' style={{display:'flex',margin:'8px 0px 0px 0px'}}>
                {ftrdCategory}
            </div>
        );
    }

    function numberWithCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const getPath=()=>{
        if(templateDocument==null || templateDocument.type==null) return null;
        return '/'+templateDocument.type.toLowerCase() +'/' + props.template_id;
    }
    const renderPhoto=()=>{
            return (
                <LazyLoadImage
                        src={templateDocument.imageUrl}
                        alt={'Image for ' + templateDocument.title + ' - trackr.cc'}
                        width={'100%'}
                        effect="blur"
                        style={{minHeight:'180px', 
                            minWidth:props.isSmall?'40vw':'300px',
                            borderRadius:'10px',
                            backgroundSize:'cover',
                            backgroundPosition:'center', 
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'cover',
                            backgroundColor:'rgb(45,55,72)',
                            border:"0"
                            }}/>
            );
        }
        let description = templateDocument.description;
        
        return ( 
            <Link className='ftrd-template-link' to={getPath()}>
                <div className='ftrd-temp'> 
                    {renderPhoto()}
                    <div className='ftrd-info' style={{padding:'0px 12px 12px 12px' }}>
                        <p className='ftrd-title'>{templateDocument.title}</p>
                        <div className='ftrd-desc'>   
                            <p className='ftrd-p'>{description}</p>
                        </div>

                        {/* {renderCategories()} */}

                        {/* {(typeof templateDocument !== 'undefined' && typeof templateDocument.metadata !== 'undefined') &&
                            <p style={{
                                // position: 'absolute', bottom: '8px',right:'0',
                                 margin:'3px 0px 0px 0px',
                                fontSize:'0.76rem',fontWeight:'600',color:'rgba(255,255,255,0.4)'
                                }}
                                >
                                {numberWithCommas(templateDocument.metadata.tryCtr) + ' people tried this'}</p>
                        } */}
           
                    </div>
                </div> 
                </Link>
        );
}

export default FeatureTemplate;