import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import { PieChart } from 'react-minimal-pie-chart';
import FirebaseUtil from '../utils/FirebaseUtil';
import PageMetaUtil from '../utils/PageMetaUtil';
import US_MAP from './maps/US_MAP';
import PH_MAP from './maps/PH_MAP.json';
import EU from './maps/EU_MAP.json';
import PH_METRO_MANILA_MAP from './maps/city/METRO_MANILA.json';
import JP_MAP from './maps/JP_MAP.json';
import KR_MAP from './maps/KR_MAP.json';
import KR_MAP_2 from './maps/KR_MAP_2.json';
import EU_MAP from './maps/EU_MAP.json';
import CA_MAP from './maps/CANADA_MAP.json';

import './MapLevel.css';

 //https://simplemaps.com/us-examples 
 //https://gist.githubusercontent.com/alvinschang/3713908/raw/de3eea752e9ba328a32f0a804d80ff8939c64f17/water.json

const MAP_ITEM_COLOR         = 'rgb(230, 230, 230)';
const MAP_ITEM_HOVERED_COLOR = 'rgb(150, 150, 150)';

class TravelMapPieChart extends Component {
    render(){

        let pieData = [];
        let statusCountOrig =  this.props.result.statusCounts;
        let has100Percent = false;
        this.props.result.statusCounts.push(this.props.result.statusCounts.shift()); // move first element to last
        this.props.result.statusCounts.forEach((status,idx)=>{
            pieData.push({value:status.percentage, color:status.color});
            if(status.percentage==100){
                has100Percent=true;
            }
        });
        let PIE_RADIUS =32;
        let LINE_WIDTH =40;
        let selectedSegment =0; // you can select which pie segment is naka angat
        return (
            <div style={{userSelect:'none',pointerEvents:'none',zIndex:'10000000'}}>
               <PieChart
                    data={pieData}
                    totalValue={100}
                    startAngle={270} 
                    label={({ dataEntry }) => Math.round(dataEntry.value)==0?'':Math.round(dataEntry.value)+'%'}
                    labelStyle={(index) => ({
                        fill: 'white',
                        fontSize: '0.4rem',
                        fontWeight:'800',
                        fontFamily: "'Lexend', sans-serif",
                        textShadow:"-1px -1px 0 rgba(30, 30, 30, 0.1),1px -1px 0  rgba(30, 30, 30, 0.4), -1px 1px 0  rgba(30, 30, 30,0.2),2px 2px 0   rgba(30, 30, 30, 0.5)"
                        
                    })}
                    lineWidth={LINE_WIDTH}  
                    segmentsStyle={{ transition: 'stroke 1s', cursor: 'pointer',zIndex:'1'}}
                    animate
                    segmentsShift={(index) => (index === selectedSegment ? 2 : 1)}
                    labelPosition={100 - LINE_WIDTH / 2}
                    radius={PIE_RADIUS} 
                    paddingAngle={has100Percent?0:0.5}
                    />
            </div>
        );
    }
}
class LegendItem extends Component { 

    render() {
        return (
            <div className='map-legend-item' style={{display:'flex',padding:isMobile?'0px':''}}>
                <div className='map-legend-color' style={{"--color":this.props.color}}></div>
                <p className='map-item-lbl'>{this.props.label}</p>
            </div>
        );
    }
}
class LegendBox extends Component {

    render() {
        let LEGEND_WIDTH = 220;
        let LEGEND_HEIGHT = 180;
        let xPos  = '0';
        let yPos  = '100%';
        let translate = 'translate(-100%,-100%)';

        if(this.props.overridePosition!=null){
            xPos=this.props.overridePosition.x;
            yPos=this.props.overridePosition.y;
        }
        else if(this.props.nanay!=null){
            let grandparentBbox = this.props.nanay.parentNode.getBoundingClientRect();
            let grandparentRect =this.props.nanay.getClientRects()[0];
            
            if(this.props.position=="BOTTOM-RIGHT"){
                let paddingX = 10;
                let grandParentScrollLeft = this.props.nanay.parentNode.scrollLeft;
                let xAdjust = grandparentRect.x<0?(grandparentRect.x*1):0;
                xPos=((grandparentBbox.width + xAdjust)-paddingX);
                xPos+=grandParentScrollLeft*2;
                xPos = xPos+'px';
                translate = 'translate(-100%,-100%)';
            }
            else if(this.props.position=="TOP-LEFT"){
                let paddingX = 10;
                let paddingY = 50 + (this.props.onExport?30:0);
                let grandParentScrollLeft = this.props.nanay.parentNode.scrollLeft;
                let xAdjust = grandparentRect.x<0?(grandparentRect.x*1):0;
                xPos=LEGEND_WIDTH -xAdjust +paddingX;
                yPos=paddingY;
                translate = 'translate(-100%,0%)';
            } 
        }

        if(this.props.statusTypes == null) return;
        let legends=[];

        this.props.statusTypes.forEach((stat,idx)=>{
            legends.push(
                <LegendItem label={stat.label} color={stat.color} isSmall={this.props.isSmall} />
            );
        });

        if(this.props.onExport==false){
            legends.shift(); // hide first status
        }
        let hasResult = this.props.result != null; // can also be considered as 'onExport' flag
        return (
            <div className='legend-box' style={{ position:'absolute', top:yPos, left:xPos, width:LEGEND_WIDTH+'px',transform:translate}}>
                <div style={{display:'flex'}}>
                    {this.props.result&&
                    <div style={{position:'absolute',marginLeft:LEGEND_WIDTH+'px'}}>
                        {/* <TravelMapPieChart result={this.props.result}/> */}
                    </div>}
                    <div >
                        {legends}
                    </div>
                </div>
            </div>
        );
    }
}
const mockdata={
    "metadata":{"tryCtr":"0"}, 
    mapId:'CA',
    instruction:"Click an area in the map to set the status.",
    title:"Europe Travel Map 🇵🇭",
    type:"MAP",
    rawData:'{"statusTypes":[{"label":"Never been","color":"rgb(230,230,230)"},{"label":"Passed by / Alighted","color":"rgb(200, 200, 200)"},{"label":"Visited","color":"rgb(149,225,211)"},{"label":"Had Vacation","color":"rgb(252,228,138)"},{"label":"Worked there","color":"rgb(243,129,129)"},{"label":"Lived there","color":" rgb(65, 141, 182)"}]}'

}

class MapLevel extends Component {

    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.state = {
            toolTip:{
                index:-1
            },
            mapItem:[],
            MAP_OBJECT: null
        }
      }

    async componentDidMount(){
        // this.setTemplateData(mockdata)
        FirebaseUtil.getTemplateDetails(this.props.templateId, this.setTemplateData);
    }
    
    getMapObject=(mapId)=>{
        if(mapId!=null){
            switch(mapId.toUpperCase()){
                case 'US': return US_MAP;
                case 'PH': return PH_MAP;
                case 'JP': return JP_MAP;
                case 'KR': return KR_MAP_2;
                case 'EU': return EU_MAP;
                case 'CA': return CA_MAP;
                case 'PH-MM': return PH_METRO_MANILA_MAP;
                
            }
        }
        return null;
    }

    
    setTemplateData=(template)=>{
        if(template==null){
            this.props.setTemplateData(null);
            return;
        }
        let mapObject = this.getMapObject(template.mapId);
        if(mapObject==null){
            this.props.setTemplateData(null);
            return;
        }
        template.meta = mapObject.meta;
        this.props.setTemplateData(template);

        let rawData = JSON.parse(template.rawData);
        this.setState({
            template:template,
            statusTypes:rawData.statusTypes,
            MAP_OBJECT: mapObject
        },()=>{
            this.buildMapItemInfo();
            this.props.setCanvasDimension(this.state.MAP_OBJECT.meta.dimension.width,this.state.MAP_OBJECT.meta.dimension.height);
        });
    }

    isTemplateLoaded=()=>{
        return this.state.template!=null;
    }

    buildMapItemInfo=()=>{
        let mapItem = [];
        this.state.MAP_OBJECT.mapItem.forEach((item,idx)=>{
            let svgMapItem = document.getElementById(this.getMapItemName(item.id));
            if(svgMapItem!=null){
                mapItem.push({
                    boundRect:svgMapItem.getBoundingClientRect(),
                    // statusIdx: Math.floor((Math.random() * 4) + 1),
                    statusIdx:0,
                    id:item.id
                });
            }
        });
        this.setState({mapItem:mapItem});
    }

    handleMapItemClick=(event)=>{
        event.preventDefault();
        const index = Number(event.target.getAttribute('index'));
        let mapItem = this.state.mapItem;
       
        if(mapItem[index].statusIdx<this.getStatusTypes().length-1){
            mapItem[index].statusIdx++;
        } else{
            mapItem[index].statusIdx=0;
        }

        this.setState({mapItem:mapItem});

    }

    getMapItemName=(id)=>{
        return 'map_item_' + id;
    }
    
    setTooltipInfo=(mapItemIndex)=>{
        if(mapItemIndex<0){
            this.setState({toolTip:{index:mapItemIndex,pos:{x:0,y:0}}});
            return;
        }

        let mapSvg= document.getElementById('map-svg');
        if(mapSvg==null) return;
        let mapSvgRect = mapSvg.getBoundingClientRect();
        
        this.setState({
            toolTip:{
                index:mapItemIndex
            }
        });
    }

    handleMapItemMouseLeave = (event) => {
        this.setTooltipInfo(-1);
    }

	handleMapItemMouseEnter = (event) => {
        const mapItemIndex = Number(event.target.getAttribute('index'));
		this.setTooltipInfo(mapItemIndex);
	};

	handleMapItemLabelMouseEnter = (event) => { 
		const targetElement = event.target;
        const mapItemIndex = Number(targetElement.getAttribute('index'));

		this.setTooltipInfo(mapItemIndex);
    }

    handleMapItemLabelMouseLeave=(event)=>{
        this.setTooltipInfo(-1);
    }
    
    getStatusTypes=(idx)=>{
        if(this.isTemplateLoaded()){
            return this.state.statusTypes;
        } else {
            return [];
        }
    }
    getStatusTypeByIdx=(idx)=>{
        if(this.isTemplateLoaded()){
            return this.state.statusTypes[idx];
        } else {
            return {label:'',color:''};
        }
    }
 
    renderMapSvg=(mapContainerWidth,onExport)=>{
        let svgMapItems=[]; 
        let scale = this.getScale(onExport);
        this.state.MAP_OBJECT.mapItem.forEach((element,idx)=>{
            let isHovered = this.state.toolTip.index === idx;
            let fillColor = !isHovered?MAP_ITEM_COLOR:MAP_ITEM_HOVERED_COLOR;
            let statusIdx = 0;
            if(typeof this.state.mapItem[idx]!=='undefined' &&
                this.state.mapItem[idx].statusIdx>0){
                statusIdx = this.state.mapItem[idx].statusIdx;
                fillColor = this.getStatusTypeByIdx(statusIdx).color;
            }

            svgMapItems.push(
                    <path
                        index={idx}
                        id={this.getMapItemName(element.id)}
                        class={'map-item ' + this.getMapItemName(element.id)}
                        fill={fillColor}
                        stroke="white"
                        stroke-opacity="1"
                        stroke-width={this.state.MAP_OBJECT.meta.stroke.width}
                        stroke-linejoin="round"
                        d={element.points} 
					    onClick={this.handleMapItemClick}
						onMouseEnter={this.handleMapItemMouseEnter}
						onMouseLeave={this.handleMapItemMouseLeave}
                        data-tooltip-id="map-item-tool-tip"
                        data-tooltip-content={this.getStatusTypeByIdx(statusIdx).label}
                        transform={'matrix('+ scale + ',0,0,' + scale + ',0,0)'}
                        fill-opacity="1"></path> 
                );
        });

        return (
            <svg id='map-svg' height="100%" width={mapContainerWidth} position="absolute" top="0" left="0" >
                {svgMapItems}
            </svg>
        );
    }

    getScale=(onExport)=>{
        let scale = this.state.MAP_OBJECT.meta.dimension.scale;
        if(onExport && this.state.MAP_OBJECT!=null
            && this.state.MAP_OBJECT.meta.exportDimension!=null
            && this.state.MAP_OBJECT.meta.exportDimension.scale!=null){
                scale = this.state.MAP_OBJECT.meta.exportDimension.scale;
        }
        return scale;
    }

    renderMapItemLabel=(mapContainerWidth,onExport)=>{
        let mapOffsetX = 0;
        const mapContainer = ReactDOM.findDOMNode(this.mapContainerRef.current);
        if(mapContainer!=null){
            let mapContainerBBox = mapContainer.getBoundingClientRect();
            let mapSvg = mapContainer.children['map-svg'];
            mapOffsetX = (mapContainerBBox.width - mapSvg.getBoundingClientRect().width);
        }

        let scale = this.getScale(onExport);
        let labels=[];
        this.state.MAP_OBJECT.mapItem.forEach((item,idx)=>{
            let name = this.state.MAP_OBJECT.mapItem[idx].name; 
            let fontSize = this.state.MAP_OBJECT.mapItem[idx].fontSize == null?
                        (isMobile?'0.9rem':'')
                        : this.state.MAP_OBJECT.mapItem[idx].fontSize;

            let fontWeight = this.getLabelFontWeight(this.props.onExport);
            let needsAssist = typeof item.assist !== 'undefined' && item.assist;

            let topOffset = -20;
            let leftOffset = needsAssist?0 : -name.length*4;
            
            let top  = item.center.y;
            let left = item.center.x + mapOffsetX;
            top  = top*scale;
            left = left*scale;
            top+=topOffset;
            left+=leftOffset;

            if(onExport && this.state.MAP_OBJECT.meta!=null && this.state.MAP_OBJECT.meta.exportFont!=null){
                fontSize = this.state.MAP_OBJECT.meta.exportFont.size;
                top +=this.state.MAP_OBJECT.meta.exportFont.offsetTop;
                left +=this.state.MAP_OBJECT.meta.exportFont.offsetLeft;
                
                left -=leftOffset;//remove old leftOffset
                leftOffset = needsAssist?0 : -name.length*this.state.MAP_OBJECT.meta.exportFont.charOffset;
                left+=leftOffset;
            }
            let isHovered = this.state.toolTip.index===idx;

            let fillColor = !isHovered?MAP_ITEM_COLOR:MAP_ITEM_HOVERED_COLOR;
            let hasState = false;
            let statusIdx = 0;
            if(typeof this.state.mapItem[idx]!=='undefined' &&
                this.state.mapItem[idx].statusIdx>0){
                statusIdx = this.state.mapItem[idx].statusIdx;
                fillColor = this.getStatusTypeByIdx(statusIdx).color;
                hasState = true;
            }
            labels.push(
                <p 
                    index={idx}
                    className={
                        (!isHovered?'map-item-label':'map-item-label-hovered') 
                        + (hasState? ' map-item-label-has-state':'') 
                        + (needsAssist? ' map-item-label-needs-assist':'')
                        }
					onClick={this.handleMapItemClick}
                    onMouseEnter={this.handleMapItemLabelMouseEnter}
					onMouseLeave={this.handleMapItemLabelMouseLeave}
                    data-tooltip-id="map-item-tool-tip"
                    data-tooltip-content={this.getStatusTypeByIdx(statusIdx).label}
                    style={needsAssist?
                             {transition:onExport?'0':'', top:top, left:left, backgroundColor:fillColor, fontWeight:fontWeight, color:(!hasState && !isHovered)?'rgba(0,0,0,0.4)':'white'}
                            :{transition:onExport?'0':'',top:top, left:left, fontWeight:isMobile?'300':fontWeight,fontSize:fontSize,lineHeight:isMobile?'1.2em':''}}
                    >{name}</p>
            );
        });
        return (
            <div style={{position:'absolute',top:'0',left:'0','width':mapContainerWidth, backgroundColor:'red'}}>
                {labels}
            </div>
        );
    }

    computeResult=()=>{
        let totalMapItem = this.state.mapItem.length;
        let statusCount = [];
        this.state.statusTypes.forEach(typ=>{
            statusCount.push({label:typ.label,color:typ.color, count:0,percentage:0})
        });

        this.state.mapItem.forEach((element,idx)=>{
            statusCount[element.statusIdx].count++;
        });

        statusCount.forEach((stat,idx)=>{
            stat.percentage =  stat.count / totalMapItem *100;
        })

        let result = {
            "totalMapItem":totalMapItem,
            "statusCounts":statusCount
        };

        return result;
    }

    getLegendPosition=()=>{
        if(this.state.template!=null && this.state.template.meta!=null && this.state.template.meta.legendPosition!=null){
             return {
                 x:this.state.template.meta.legendPosition.left+'px',
                 y:this.state.template.meta.legendPosition.top+'px'
             }
        }
        return null;
     }

     getExportPieInfo=()=>{
        if(this.state.template!=null && this.state.template.meta!=null){
             return this.state.template.meta.exportPieInfo;
        }
        return {top:'',left:'',width:''};
     }

     showLabels=(onExport)=>{
        return onExport==false || 
            (this.state.template!=null &&
             this.state.template.meta!=null && 
             this.state.template.meta.showLabelOnExport!=null && 
             this.state.template.meta.showLabelOnExport);
     }

     getLabelFontWeight=(onExport)=>{
        if(onExport && this.state.template!=null &&
             this.state.template.meta!=null && 
             this.state.template.meta.exportFontWeight!=null){
                return this.state.template.meta.exportFontWeight;
        }
        return '';
     }
    render() {
        let onExport = this.props.onExport; // always have this variable, for debugging purposes
        // let onExport = true; // always have this variable, for debugging purposes
        if(this.state.MAP_OBJECT==null) return null;
        let mapContainerWidth = '0px';
        let mapContainerHeight = '0px';
        let scale = this.getScale(onExport);
        if(this.state.template!=null){
            mapContainerWidth  = (this.state.MAP_OBJECT.meta.dimension.width * scale)+'px';
            mapContainerHeight = (this.state.MAP_OBJECT.meta.dimension.height * scale)+'px';
        }
        return (
            <div className='map-level trakr' style={{width:mapContainerWidth,overflow:onExport?'':'hidden'}}>
                {PageMetaUtil.generateHelmetFromTemplate(this.props.templateId, this.state.template)}
                <Tooltip className='tool-tip' id="map-item-tool-tip" place="top" variant="dark" style={{zIndex:'100','--rt-opacity':onExport?'0':''}}/>
                
                {this.state.template.title!=null &&
                <h3 className={onExport?'export-title':(isMobile?'tracker-title-mobile':'tracker-title')}>{this.state.template.title}</h3>}
                    
                <div className={'travel-map-result' + (onExport?' tmr-export-1':'')} style={this.getExportPieInfo()}>
                    <TravelMapPieChart onExport={onExport} result={this.computeResult()}/>
                </div>
                
                <LegendBox 
                    statusTypes={this.state.statusTypes}
                    isSmall={this.props.exchange.isSmall}
                    nanay={ReactDOM.findDOMNode(this)}
                    position={this.state.MAP_OBJECT.legendPosition}
                    onExport={onExport}
                    result={onExport?this.computeResult():null}
                    overridePosition={onExport?
                        {
                        x:this.state.template.meta.exportLegendInfo.left+'px',
                        y:this.state.template.meta.exportLegendInfo.top+'px'}:
                       this.getLegendPosition()}

                />
                <div className='map-container' ref={this.mapContainerRef}
                    style={{visibility:this.state.template==null?'hidden':'',width:mapContainerWidth,height:mapContainerHeight}}>
                   {this.renderMapSvg(mapContainerWidth,onExport)}
                   {this.showLabels(onExport) && this.renderMapItemLabel(mapContainerWidth,onExport)}
                </div>
            </div>
        );
    }
}

export default MapLevel;