import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import PageMetaUtil from '../utils/PageMetaUtil';
import TrackerItem from '../components/TrackerItem';
import ProgressBar from '../components/ProgressBar';
import FirebaseUtil from '../utils/FirebaseUtil';

class LegendItem extends Component { 

    render() {
        let fontSize=isMobile?'0.7rem':this.props.isSmall?'0.8rem':'';
        return (
            <div className='legend-item' style={{display:'flex',padding:isMobile?'0px':''}}>
                <div className='legend-item-color' style={{backgroundColor:this.props.color,minWidth:isMobile?'10px':'20px',minHeight:isMobile?'10px':'20px'}}></div>
                <p className='legend-item-label logo-font' style={{fontSize:fontSize}}>{this.props.label}</p>
            </div>
        );
    }
}
class PercentageProgressTracker extends Component {

    constructor(props) {
        super(props); 
        this.state ={
            canvasWidth:700,
            canvasHeight:870
        }
    }
    setTemplateData=(template)=>{
        this.restoreDefault(template);
        this.props.setTemplateData(template);
    }

    async componentDidMount(){ 
        FirebaseUtil.getTemplateDetails(this.props.templateId, this.setTemplateData);
    }

    restoreDefault=(template)=>{
        if(template == null) return null;
        let templateData = JSON.parse(template.rawData);
        let trackItemsInfo=[];
        templateData.trackItemsInfo.forEach((item,idx)=>{
            item.statusIdx=0;
            trackItemsInfo.push(item);
        });

        this.setState({
            template:template,
            statusTypes:templateData.statusTypes,
            trackItemsInfo:trackItemsInfo,
            exportProperties: this.getExportProperties(templateData.trackItemsInfo.length)
        },);
    }
  
    getExportProperties=(itemSize)=>{
        let mappingIdx= itemSize-1;
        let xSizeMapping=[
            1,2,3,2,3,3,3,3,3
           ,4,4,4,4,4,4,4,4,4,4 //10's
           ,4,5,5,5,5,5,5,5,5,5 //20's
           ,5,6,6,6,6,6,6,6,6,6 //30's
           ,6,6,6,7,7,7,7,7,7,7 //40's
           ,7,7,7,7,7,7,7       //50's
       ];

       let xSize = xSizeMapping[mappingIdx];
       let vSizeEstimate = itemSize/xSize;
       let vSize = vSizeEstimate<1?1:Math.ceil(vSizeEstimate);
       let itemWidthMapping=[
              220,220,200,200,180,180,188,180,180
             ,160,164,164,154,154,154,154,154,154,154 // 10's
             ,154,130,130,130,130,130,110,110,110,110 // 20's
             ,110,100,100,100,100,100,100,98,98,98    // 30's
             ,98,98,98,84,84,84,84,84,84,84           // 40's
             ,72,72,72,72,72,72,72                    // 50's
       ];

       let itemWidth = itemWidthMapping[mappingIdx];
       let itemHeight = itemWidthMapping[mappingIdx];

       let marginRight = 2;
       let marginBottom = 2;
       let canvasWidth = xSizeMapping[mappingIdx] * (itemWidth+marginRight) + 40;
       let canvasHeight = vSize * (itemHeight+marginBottom) + 200;
       this.props.setCanvasDimension(canvasWidth,canvasHeight);
       
        let ret= {
            itemWidth:itemWidth+'px',
            itemHeight:itemHeight+'px',
            canvasWidth:canvasWidth,
            canvasHeight:canvasHeight
        };
        return ret;

    }
    
    clickTracker=(idx)=>{
        let trackItemsInfo=this.state.trackItemsInfo;
        trackItemsInfo[idx].statusIdx=
            trackItemsInfo[idx].statusIdx+1<this.state.statusTypes.length?
                trackItemsInfo[idx].statusIdx+1:0;
       
        this.setState({trackItemsInfo:trackItemsInfo});
    }

    renderTrackItems=()=>{

        if(typeof this.state.trackItemsInfo === 'undefined') return;
        let trakcerItems=[];
        this.state.trackItemsInfo.forEach((trx,idx)=>{
            trakcerItems.push(
                <TrackerItem 
                 isMobile={isMobile}
                 isSmall={this.props.isSmall}
                 onExport={this.props.onExport}
                 exportWidth={this.state.exportProperties.itemWidth}
                 exportHeight={this.state.exportProperties.itemHeight}
                 info={trx}
                 opacity={(this.props.onExport && trx.statusIdx===0)?'0.6':'1'}
                 status={this.state.statusTypes[trx.statusIdx]}
                 onClick={()=>{this.clickTracker(idx);}}
                 />
            );
        });
        return (
            <div className='trackr-items' style={{textAlign:'center', marginTop:this.props.onExport?'10px':'25px'}}>
                {trakcerItems}
            </div>
        );
    }

    renderLegend=()=>{
        if(typeof this.state.statusTypes === 'undefined') return;
        let legends=[];

        this.state.statusTypes.forEach((stat,idx)=>{
            legends.push(
                <LegendItem label={stat.label} color={stat.color} isSmall={this.props.isSmall} />
            );
        });
        legends.shift();
        return (
            <div className='legend' style={{margin:isMobile?'0px 0px 10px 0px':'0px 12px',justifyContent:'center',display:'flex'}}>
              {legends}
            </div>
        );
    }

    getPercentage=()=>{

        if(typeof this.state.trackItemsInfo === 'undefined') return 0;
        let pointCtr = 0;
        this.state.trackItemsInfo.forEach((trx,idx)=>{
            pointCtr+=this.state.statusTypes[trx.statusIdx].point;
        });
        return (pointCtr/this.state.trackItemsInfo.length)*100;
    }
    
    renderProgressBar=(onExport)=>{
        let percentage=this.getPercentage();
        return (
            <div className='progressbar-container' style={onExport?{padding:'0px 100px'}:{padding:'0px 26vw'}}>
                <ProgressBar percentage={percentage}/>
            </div>
        );
    }

    render() {
        let onExport = this.props.onExport; // always have this variable, for debugging purposes
        return (
            <div className='trakr percentage-trackr' >
                 {PageMetaUtil.generateHelmetFromTemplate(this.props.templateId, this.state.template)}

                {this.state.template!=null &&
                <h3 className={onExport?'export-title':(isMobile?'tracker-title-mobile':'tracker-title')}>{this.state.template.title}</h3>} 
                {!onExport&& this.renderLegend()}
                {this.renderProgressBar(onExport)}
                {this.renderTrackItems()}
                    
                {onExport && this.renderLegend()}

            </div>
        );
    }
}

export default PercentageProgressTracker;